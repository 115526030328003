<template>
  <div class="sliders_control">
    <VueSlider ref="slider" @change="sliderChange" @drag-end="$refs.slider.blur()" v-model="selectedPriceRange"
      :min="25000" :max="250000" :interval="1000" tooltip="none" :enableCross="false" :dot-size="16"
      :dot-options="sliderOptions.dotOptions" :rail-style="sliderOptions.railStyle" :process="false" />
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

export default {
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    const sliderOptions = {
      dotOptions: {
        style: {
          'background-color': '#0c6dfd',
          padding: '2px 2px 2px 2px',
        },
        focusStyle: {
          'background-color': '#b6d4fe',
          'border-color': '#0c6dfd',
          'border-width': '1px',
          'box-shadow': '0px 0px 7px 0px #0c6dfd',
        }
      },
      railStyle: {
        'background-color': '#dee2e6',
        padding: '2px 2px 2px 2px',
        height: '8px',
        'border-radius': '18px'
      },

    }
    return {
      sliderOptions,
      selectedPriceRange: [this.value?.min || 40000, this.value?.max || 70000],
    };
  },
  mounted() {
    this.$emit("input", { min: this.selectedPriceRange[0], max: this.selectedPriceRange[1] });
  },
  methods: {
    sliderChange(priceRange) {
      this.$emit("input", { min: priceRange[0], max: priceRange[1] });
    }
  },
  components: { VueSlider },
};
</script>
<style lang="scss" scoped></style>
